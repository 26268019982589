import { iViewPortalDirectusUser } from '~/interfaces'
const { signOut, getSession } = useAuth()
const { $clientPosthog } = useNuxtApp()
interface State {
	user: iViewPortalDirectusUser | null,
	urlCallback: string
}
export const useCompanyStore = defineStore('storeCompany', {
	state: (): State => {
		return {
			user: null,
			urlCallback: ''
		}
	},
	getters: {
		getUser: (state: State) => state.user
	},
	actions: {
		setUser(user: iViewPortalDirectusUser | null) {
			this.user = user
			const _urlCallback = this.user?.customization?.app_url || '/'
			this.urlCallback = `/${_urlCallback}/login`
			/* useThemeStore().setOptionFromUser(this.user?.customization?.theme_colors || '') */
			/* $clientPosthog.identify(user?.email, {}, {
				referral_code: user?.company?.id
			}) */
		},
		async loadUser() {
			// if (this.user) { return }
			const session = await getSession()
			if (session) {
				this.setUser(session.user as iViewPortalDirectusUser)
			} else {
				// refresh token???
				// const session = await getSession()
			}
		},
		async logout() {
			await signOut({ callbackUrl: this.urlCallback })
			$clientPosthog.capture('logout')
			$clientPosthog.reset()
			// this.setUser(null)
		}
	}
})
// if (import.meta.hot) { import.meta.hot.accept(acceptHMRUpdate(useCompanyStore, import.meta.hot)) }
